var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default",null,null,{
      results: _vm.results,
      loading: _vm.loading,
      processing: _vm.processing,
      hasInitialCall: !_vm.$_.isEmpty(_vm.results),
      loadMore: _vm.loadMore
    })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }