<template>
  <div>
    <slot
      v-bind="{
        results,
        loading,
        processing,
        hasInitialCall: !$_.isEmpty(results),
        loadMore
      }"
    />
  </div>
</template>

<script>
import AlgoliaSearch from "algoliasearch/lite";
const defaultParams = {
  hitsPerPage: 20
};
export default {
  props: {
    query: {
      type: Array,
      required: true
    },
    debounce: {
      type: Number,
      default: 150
    }
  },
  data() {
    return {
      results: {},
      loading: false,
      processing: false,
      debounceSearch: this.$_.debounce(this.searchAndLoad, this.debounce),
      page: 0
    };
  },
  computed: {
    credentials() {
      return this.$store.getters["algolia/data"]("agentCredentials");
    },
    client() {
      if (!this.credentials) return null;
      return AlgoliaSearch(this.credentials.appId, this.credentials.apiKey);
    }
  },
  async created() {
    this.$watch("query", this.queryWatchHandler, {
      immediate: true,
      deep: true
    });
  },
  methods: {
    queryWatchHandler(newQuery, oldQuery) {
      if (this.$_.isEqual(newQuery, oldQuery)) return;
      this.$_.forEach(newQuery, i => {
        if (i.query) {
          this.page = 0;
          this.loading = true;
          this.debounceSearch();
        }
      });
    },
    loadMore() {
      const hitsPerPage = this.$_.get(
        this.query,
        "[0].params.hitsPerPage",
        defaultParams.hitsPerPage
      );
      this.searchAndProcess({
        page: ++this.page,
        hitsPerPage: hitsPerPage
      });
    },
    searchAndProcess(params) {
      this.processing = true;
      return this.search(params).finally(() => {
        this.processing = false;
      });
    },
    searchAndLoad() {
      this.loading = true;
      return this.search().finally(() => {
        this.loading = false;
      });
    },
    setResults(newResults) {
      this.$_.forEach(newResults, (i, index) => {
        newResults[index]["isComplete"] =
          (newResults[index].page + 1) * newResults[index].hitsPerPage >=
          newResults[index].nbHits;
      });
      return newResults;
    },
    mergeResults(newResults) {
      this.$_.forEach(this.results, (i, index) => {
        const oldHits = i.hits;
        newResults[index].hits = [...oldHits, ...newResults[index].hits];
        newResults[index]["isComplete"] =
          (newResults[index].page + 1) * newResults[index].hitsPerPage >=
          newResults[index].nbHits;
      });
      return newResults;
    },
    search(params) {
      const query = this.query.map(i =>
        this.$_.merge({}, { params: defaultParams }, i, { params })
      );
      return this.client
        .search(query)
        .then(res => {
          const newResults = this.$_.get(res, "results");
          if (this.page > 0) this.results = this.mergeResults(newResults);
          else this.results = this.setResults(newResults);
          this.$emit("results", this.results);
        })
        .catch(error => {
          this.$emit("fallback-to-firebase");
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
